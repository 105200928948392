import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/footer.js";
import Loading from "./pages/Loading";
// import ScrollToTop from "./utils/ScrollToTop.js";
import "bootstrap/dist/js/bootstrap";
const Homepage = lazy(() => import("./pages/Homepage.js"));
const About = lazy(() => import("./pages/About.js"));
const Video = lazy(() => import("./pages/Video.js"));
const HealthCare = lazy(() => import("./pages/HealthCare.js"));
const History = lazy(() => import("./pages/History.js"));
const Accelerator = lazy(() => import("./pages/Accelerator.js"));
const HospitalCompany = lazy(() => import("./pages/HospitalCompany.js"));
const NotFound = lazy(() => import("./pages/NotFound.js"));

const App = () => {
    const [ scrollPosition, setScrollPosition ] = useState(0);

    // 監聽視窗的滾動事件，並更新滾動位置
    useEffect(() => {
      const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
      };
  
      window.addEventListener('scroll', handleScroll);
  
      // 在組件卸載時清除事件監聽器
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
        <div>
            <Nav scrollPosition={scrollPosition} />
            {/* <ScrollToTop /> */}
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route exact path="/" element={<Homepage />}></Route>
                    <Route exact path="/about" element={<About />}></Route>
                    <Route exact path="/video" element={<Video />}></Route>
                    <Route exact path="/profile" element={<HealthCare />}></Route>
                    <Route exact path="/history" element={<History />}></Route>
                    <Route exact path="/accelerator" element={<Accelerator />}></Route>
                    <Route exact path="/cooperation" element={<HospitalCompany />}></Route>
                    {/* <Route path="*" element={<NotFound />}></Route> */}
                </Routes>
            </Suspense>
            <Footer />
        </div>
    )
}

export default App;