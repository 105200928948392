import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logoPC from "../assets/images/header/Home_Logo.png";
import logoPad from "../assets/images/header/Home_Logo_pad.png";
import logoPhone from "../assets/images/header/Home_Logo_phone1-01_0.png";

const Nav = ({ scrollPosition }) => {
    const [ offcanvasHasBeenOpen, setOffCanvasHasBeenOpen ] = useState(false);

    const Navigate = useNavigate();
    
    // 不直接用 Link 是因為還要關閉 offcanvas
    const handleGoToOtherPages = (e) => {
        console.log(e.target.innerText)
        let btn = document.querySelector(".btn-close");
        switch (e.target.innerText) {
            case "HOME":
                Navigate("/");
                break;
            case "ABOUT US":
                Navigate("/about");
                break;
            case "VIDEO":
                Navigate("/video");
                break;
            case "PROFILE":
                Navigate("/profile");
                break;
            case "HISTORY":
                Navigate("/history");
                break;
            case "ACCELERATOR":
                Navigate("/accelerator");
                break;
            case "COOPERATION":
                Navigate("/cooperation");
                break;        
        }
        btn.click();
        window.scrollTo({ // 這個是專門因應「登出前就已經在 Homepage」的情形
            top: "0",
            behavior: "instant",
        });
    }

    let mainUl = document.querySelector("#menuMainList");
    let ecosystemUl = document.querySelector("#menuEcosystemList");
    let hospitalUl = document.querySelector("#menuHospitalList");    

    function getMenuLists() {
        mainUl = document.querySelector("#menuMainList");
        ecosystemUl = document.querySelector("#menuEcosystemList");
        hospitalUl = document.querySelector("#menuHospitalList");    
    }
    if (!mainUl || !ecosystemUl || !hospitalUl) {
        setTimeout(() => getMenuLists(), 100);
    };

    const handleShowMainList = () => {
        console.log("inside function")
        mainUl.classList.remove("d-none");
        ecosystemUl.classList.add("d-none");
        hospitalUl.classList.add("d-none");
    }

    const handleShowHospitalList = () => {
        mainUl.classList.add("d-none");
        ecosystemUl.classList.add("d-none");
        hospitalUl.classList.remove("d-none");
    }

    const handleShowEcosystemList = () => {
        mainUl.classList.add("d-none");
        ecosystemUl.classList.remove("d-none");
        hospitalUl.classList.add("d-none");
    }
    
    const handleToggleSMEList = () => {
        const SMEList = document.querySelector("#SMEList");
        const SSHList = document.querySelector("#SSHList");
        if (SMEList.classList.contains("d-none")) {
            SSHList.classList.add("d-none");
        }
        SMEList.classList.toggle("d-none");
    }

    const handleToggleSSHList = () => {
        const SSHList = document.querySelector("#SSHList");
        const SMEList = document.querySelector("#SMEList");
        if (SSHList.classList.contains("d-none")) {
            SMEList.classList.add("d-none");
        }
        SSHList.classList.toggle("d-none");
    }

    const handleCloseSublists = () => {
        const nav = document.querySelector("ul.navbar-nav");
        const sublists = nav.querySelectorAll("ul");
        sublists.forEach((i) => i.classList.add("d-none"));
    }

    useEffect(() => getMenuLists(), []);

    useEffect(() => {
        console.log(scrollPosition);
        const SSHList = document.querySelector("#SSHList");
        const SSHSublists = SSHList.querySelectorAll("li");
        const SMEist = document.querySelector("#SMEList");
        const SMESublists = SMEist.querySelectorAll("li");
        if (scrollPosition > window.innerHeight * 0.08) {
            SSHSublists.forEach((i) => {
                i.classList.add("bg-primary");
                i.classList.add("hover-secondary");
                i.classList.remove("hover-primary");
            });
            SMESublists.forEach((i) => {
                i.classList.add("bg-primary");
                i.classList.add("hover-secondary");
                i.classList.remove("hover-primary");
            });
        } else {
            SSHSublists.forEach((i) => {
                i.classList.remove("bg-primary");
                i.classList.add("hover-primary");
                i.classList.remove("hover-secondary");
            });
            SMESublists.forEach((i) => {
                i.classList.remove("bg-primary");
                i.classList.add("hover-primary");
                i.classList.remove("hover-secondary");
            });
        }
    }, [scrollPosition]);

    useEffect(() => {
        // 每次打開 offcanvas 時都要從 mainUl 開始顯示
        if (offcanvasHasBeenOpen && mainUl && ecosystemUl && hospitalUl) {
            handleShowMainList();
            setOffCanvasHasBeenOpen(false);
        }
    }, [offcanvasHasBeenOpen]);

    return (
        <>
            <section className="position-sticky top-0 z-3 bg-primary" style={{height: "10vh"}}>
                <nav className="navbar navbar-expand d-none d-lg-block p-0 h-100">
                    <div className="container d-flex justify-content-between px-0 row h-100">
                        <Link to="/" className="d-flex col-xl-5" style={{width: "45%"}}>
                            <img src={logoPC} alt="logo" className="object-fit-cover img-fluid"  />
                            <h1 className="fs-2"></h1>
                        </Link>
                        <ul className="navbar-nav fs-xl-2 col-xl-7 h-100 pe-0 justify-content-end" style={{fontSize: "0.85rem", width: "55%"}}>
                            <li className="d-flex align-items-center py-0 border-bottom border-4 border-primary hover-borderb-secondary hover-secondary">
                                <Link className="nav-link text-white text-center flex-grow-1 h-fit-content py-2" to="/" onClick={handleCloseSublists}>HOME</Link>
                            </li>
                            <li className="d-flex align-items-center py-0 border-bottom border-4 border-primary hover-borderb-secondary hover-secondary">
                                <Link className="nav-link text-white text-center flex-grow-1 h-fit-content py-2" to="/about" onClick={handleCloseSublists}>ABOUT US</Link>
                            </li>
                            <li className="nav-link text-white flex-grow-2 align-items-center justify-content-center py-0 cursor-pointer d-flex border-bottom border-4 border-primary hover-borderb-secondary hover-p-secondary position-relative" onClick={handleToggleSSHList}>
                                <div className="h-fit-content py-2">
                                    <p className="text-center">SHOW CHWAN</p>
                                    <p className="text-center">SMART HOSPITAL</p>
                                </div>
                                <ul className={`position-absolute bg-opacity-25 bg-white d-none top-0 w-100 text-center`} id="SSHList" style={{marginTop: "10vh"}}>
                                    <li className="d-flex px-2 hover-primary">
                                        <Link className="px-3 py-2 rounded-0 text-white border-1 border-bottom border-white w-100" to="/video" onClick={handleCloseSublists}>VIDEO</Link>
                                    </li>
                                    <li className="d-flex px-2 hover-primary">
                                        <Link className="px-3 py-2 rounded-0 text-white border-1 border-bottom border-white w-100" to="/profile" onClick={handleCloseSublists}>PROFILE</Link>
                                    </li>
                                    <li className="d-flex px-2 hover-primary">
                                        <Link className="px-3 py-2 rounded-0 text-white w-100" to="/history" onClick={handleCloseSublists}>HISTORY</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-link text-white flex-grow-2 align-items-center justify-content-center py-0 cursor-pointer d-flex border-bottom border-4 border-primary hover-borderb-secondary hover-p-secondary position-relative" onClick={handleToggleSMEList}>
                                <div className="h-fit-content py-2">
                                    <p className="text-center">SMART MEDICINE</p>
                                    <p className="text-center">ECOSYSTEM</p>
                                </div>
                                <ul className={`position-absolute bg-opacity-25 bg-white d-none top-0 w-100 text-center`} id="SMEList" style={{marginTop: "10vh"}}>
                                    <li className="d-flex px-2 hover-primary">
                                        <Link className="px-3 py-2 rounded-0 text-white border-1 border-bottom border-white w-100" to="/accelerator" onClick={handleCloseSublists}>ACCELERATOR</Link>
                                    </li>
                                    <li className="d-flex px-2 hover-primary">
                                        <Link className="px-3 py-2 rounded-0 text-white w-100" to="/cooperation" onClick={handleCloseSublists}>COOPERATION</Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>

                <nav className="d-flex d-lg-none py-4 px-0 h-100">
                    <div className="container d-flex justify-content-between h-100">
                        <div className="d-xs-none d-flex align-items-center">
                            <h1 className="fs-2 text-third d-flex">
                                <Link to="/" className="text-nowrap overflow-hidden" style={{background: `url(${logoPhone}) center/cover`, width: "281px", height: "30px", textIndent: "101%"}}>
                                    
                                </Link>
                            </h1>
                        </div>
                        <div className="d-none d-xs-block d-sm-none">
                            <h1 className="fs-2 text-third d-flex">
                                <Link to="/" className="text-nowrap overflow-hidden" style={{background: `url(${logoPhone}) center/cover`, width: "376px", height: "46px", textIndent: "101%"}}>
                                    
                                </Link>
                            </h1>
                        </div>
                        <div className="d-none d-sm-flex d-md-none align-items-center">
                            <h1 className="fs-2 text-third d-flex">
                                <Link to="/" className="text-nowrap overflow-hidden" style={{background: `url(${logoPad}) center/cover`, width: "399px", height: "25px", textIndent: "101%"}}>
                                    
                                </Link>
                            </h1>
                        </div>
                        <div className="d-none d-md-flex align-items-center">
                            <h1 className="fs-2 text-third d-flex">
                                <Link to="/" className="text-nowrap overflow-hidden" style={{background: `url(${logoPad}) center/cover`, width: "509px", height: "36px", textIndent: "101%"}}>
                                    
                                </Link>
                            </h1>
                        </div>
                        <div className="">
                            <button className="btn h-100 px-0 py-3 mt-xs-1 mt-sm-0 d-flex flex-column justify-content-between" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" onClick={() => setOffCanvasHasBeenOpen(true)}>
                                <div className="border border-1 border-white w-6 w-xs-7 w-sm-8 w-md-9"></div>
                                <div className="border border-1 border-white w-6 w-xs-7 w-sm-8 w-md-9"></div>
                                <div className="border border-1 border-white w-6 w-xs-7 w-sm-8 w-md-9"></div>
                            </button>
                        </div>
                    </div>
                </nav>
            </section>
            
            <div className="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="true" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width: "375px", background: "#061332"}}>
                <div className="offcanvas-header d-flex justify-content-end p-0">
                    <button type="button" className="btn-close btn fs-10 mt-3 me-8 p-2 h-fit-content text-white" data-bs-dismiss="offcanvas" aria-label="Close">×</button>
                </div>
                <div className="offcanvas-body">
                    <ul id="menuMainList">
                        <li>
                            <button type="button" className="btn text-start text-white" onClick={handleGoToOtherPages}>HOME</button>
                        </li>
                        <li>
                            <button type="button" className="btn text-start text-white" onClick={handleGoToOtherPages}>ABOUT US</button>
                        </li>

                        <li>
                            <button type="button" className="btn text-start text-white d-flex justify-content-between flex-nowrap" onClick={handleShowHospitalList}>
                                SHOW CHWAN SMART HOSPITAL
                                <span className="material-symbols-outlined align-self-end fs-10">
                                    chevron_right
                                </span>
                            </button>
                        </li>
                        <li>
                        <button type="button" className="btn text-start text-white d-flex justify-content-between flex-nowrap" onClick={handleShowEcosystemList}>
                                SMART MEDICINE ECOSYSTEM
                                <span className="material-symbols-outlined align-self-end fs-10">
                                    chevron_right
                                </span>
                            </button>
                        </li>
                    </ul>                                
                    
                    {/* SMART HOSPITAL list */}
                    <ul className="d-none" id="menuHospitalList">
                        <li>
                            <button type="button" className="btn text-start text-white" onClick={handleGoToOtherPages}>VIDEO</button>
                        </li>
                        <li>
                            <button type="button" className="btn text-start text-white" onClick={handleGoToOtherPages}>PROFILE</button>
                        </li>
                        <li>
                            <button type="button" className="btn text-start text-white" onClick={handleGoToOtherPages}>HISTORY</button>
                        </li>
                        <li className="cursor-pointer" onClick={handleShowMainList}>
                            <span className="material-symbols-outlined align-self-end fs-10 text-white py-3 mt-5 ms-n2">
                                chevron_left
                            </span>
                        </li>
                    </ul>

                    {/* ECOSYSTEM list */}
                    <ul className="d-none" id="menuEcosystemList">
                        <li>
                            <button type="button" className="btn text-start text-white" onClick={handleGoToOtherPages}>ACCELERATOR</button>
                        </li>
                        <li>
                            <button type="button" className="btn text-start text-white" onClick={handleGoToOtherPages}>COOPERATION</button>
                        </li>
                        <li className="cursor-pointer" onClick={handleShowMainList}>
                            <span className="material-symbols-outlined align-self-end fs-10 text-white py-3 mt-5 ms-n2">
                                chevron_left
                            </span>
                        </li>
                    </ul>

                </div>
            </div>
        </>    
    )
}

export default Nav;